import React from 'react'

import { useSelector } from "react-redux";
import { selectUser } from "../slices/userSlice";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSlash } from '@fortawesome/free-solid-svg-icons'

const Home = () => {

    const user = useSelector(selectUser);

    return (
        <div className="footer row">
            {user.isLogged ? (
                <div  className="flex-100">
                    <p>{user.infos.prenom} {user.infos.nom} <Link to="/Logout"><FontAwesomeIcon icon={faUserSlash} /></Link></p>
                    <Link to="/Profil">Mes annonces</Link>
                </div>
            ) : (
                <p className="flex-100">
                    <Link to="/Login">Se connecter</Link>
                    |
                    <Link to="/Register">S'enregistrer</Link>
                </p>
            )
            }
        </div>
    )
}

export default Home