import React, { useState, useEffect } from "react";
import moment from 'moment'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { loadURL, selectSalon } from '../slices/salonSlice'
import { CovoitById } from '../api/covoit'
import ListeReponses from './Liste_reponses'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faPersonWalking, faPerson, faPersonDress, faPersonHalfDress } from '@fortawesome/free-solid-svg-icons'

const Home = (props) => {
    const dispatch = useDispatch()

    const salon = useSelector(selectSalon);
    const [salonCovoit, setSalonCovoit] = useState([])

    useEffect(() => {
        // pour récupérer les paramètres de l'URL
        const queryParams = new URLSearchParams(window.location.search)
        // récupère les paramétres de l'url
        const localStorage = {
            salon: queryParams.get("salon"),
            date: queryParams.get("date"),
            id_salon: queryParams.get("id_salon")
        }
        // on stocke dans le store redux
        dispatch(loadURL(localStorage))
        //console.log("localStorage", localStorage)

        // ajouter les paramètres d'URL dans le localstorage
        // Bretelles et ceinture pour pouvoir récuperer les infos dans redux et le localStorage
        // Ca sert dans les form de déposer si l'user rafraichit la page
        window.localStorage.setItem("url_salon", localStorage.salon);
        window.localStorage.setItem("url_date", localStorage.date);
        window.localStorage.setItem("url_id_salon", localStorage.id_salon);

        // on recherche tous les covoiturages pour ce salon

        // Pour la prod remettre cet appel de redux
        //CovoitById(salon.infos.id_salon)

        CovoitById(localStorage.id_salon)
            .then((result) => {
                // on stocke les covoits dans salonCovoit
                setSalonCovoit(result.covoitDetail)
                //console.log("Covoiturages pour ", salonCovoit)
            })
            .catch(err => console.log(err))
    }, [props, dispatch])

    const Type = (props) => {
        if (props.liste.choix === "Recherche") { return (<FontAwesomeIcon className="icon_x4 round_icon" icon={faPersonWalking} />) }
        else { return (<FontAwesomeIcon className="icon_x4 round_icon" icon={faCar} />) }
    }

    const Genre = (props) => {
        if (props.liste.genre === "masc") { return (<FontAwesomeIcon className="icon_x2" icon={faPerson} />) }
        else if (props.liste.genre === "fem") { return (<FontAwesomeIcon className="icon_x2" icon={faPersonDress} />) }
        else { return (<FontAwesomeIcon className="icon_x2" icon={faPersonHalfDress} />) }
    }

    const Message = (props) => {
        if (props.liste.message) { return (<p className="message" >{props.liste.message}</p>) }
        else { return (<p></p>) }
    }

    const DateCrea = (props) => {
        return (moment(props.liste.date_crea).format('DD-MM-yyyy'))
    }

    return (
        <div className="containeur">

            <h1> {salonCovoit.length} covoiturage(s) pour <br />{salon.infos.salon}</h1>
            <div className="deposer">
                <Link className="button-form deposer" to="/Deposer"> Déposer une annonce </Link>
            </div>

            <section>
                {salonCovoit.map((liste) => {
                    return (
                        <div className={`liste_covoit flex column ${liste.choix}`} key={liste.id}>

                            <div>
                                <div className="row">
                                    <Type key={liste.id} liste={liste} />
                                    <div className="row">
                               <h3><Genre liste={liste} /> {liste.prenom} <span className="p14">{liste.age} ans </span> &nbsp;</h3>
                                    <h3> {liste.choix} {liste.places} place(s) </h3>
 
                                    </div>
                                </div>
                                <p>De : <b>{liste.depart}</b>, départ le <b>{liste.date_aller}</b>  à  <b>{liste.heure}</b> </p>
                                <p>Contrepartie : <b>{liste.contrepartie}</b></p>
                                <Message liste={liste} />
                            </div>

                            <div className="column" >
                                <div className="row">
                                    <p>Publié le : <b><DateCrea liste={liste} /></b></p>
                                    <i><ListeReponses liste={liste} /> interaction(s)</i>
                                </div>
                                <Link className="bouton" to={`/reponse_covoit/${liste.id}`}> Prendre contact </Link>
                            </div>
                        </div>
                    )
                })}
            </section>
        </div>
    )
}

export default Home
