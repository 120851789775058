export const config = {
  api_url: "https://back2.vinsnaturels.fr",
};


/*
  api_url: "http://localhost:9500",

*/
/*
  api_url: "https://back2.vinsnaturels.fr",

*/