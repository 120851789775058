import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faPhone, faVenusMars } from '@fortawesome/free-solid-svg-icons'

import { useSelector } from "react-redux";
import { selectUser } from "../slices/userSlice";
import Navigation from './Navigation'


import moment from 'moment'
import { repCovoit } from '../api/reponse'
import { CovoitDetail } from '../api/covoit'

const Reponse_covoit = (props) => {
    const user = useSelector(selectUser);
    const params = useParams();
    const [salonCovoit, setSalonCovoit] = useState([])

    const [type, setType] = useState("")
    const [ok, setOk] = useState("")


    const age = useRef();
    const email = useRef();
    const choix = useRef();
    const contrepartie_texte = useRef();
    const depart = useRef();
    const date_aller = useRef();
    const heure = useRef();
    const genre = useRef();
    const message = useRef();
    const places = useRef();
    const telephone = useRef();

    useEffect(() => { // useEffect hook
        window.scrollTo(0, 0)
        //console.log("params.id", params.id)
        CovoitDetail(params.id)
            .then((detail) => {
                //console.log("getAllCovoitById", detail)
                setSalonCovoit(detail)
            })
            .catch(err => console.log(err))
    }, [params.id]);


    const onSubmitForm = (e) => {
        const newAge = age.current.value;
        const newChoix = choix.current.value;
        const newContrepartie_texte = contrepartie_texte.current.value;
        const newDepart = depart.current.value;
        const newHeure = heure.current.value;
        const newGenre = genre.current.value;
        const newMail = email.current.value;
        const newMessage = message.current.value;
        const newPlaces = places.current.value;
        const newTelephone = telephone.current.value;

        const newData = {
            age: newAge,
            arrivee: salonCovoit.arrivee,
            choix: newChoix,
            contrepartie: salonCovoit.contrepartie,
            contrepartie_texte: newContrepartie_texte,
            date_aller: salonCovoit.date_aller,
            depart: newDepart,
            destinataire: salonCovoit.email,
            heure: newHeure,
            email: newMail,
            id_annonce: salonCovoit.id,
            id_membre: user.infos.id,
            id_salon: salonCovoit.id_salon,
            genre: newGenre,
            message: newMessage,
            nom: user.infos.nom,
            places: newPlaces,
            prenom: user.infos.prenom,
            telephone: newTelephone,
        }
        // console.log("newData : ", newData)


        repCovoit(newData)
            .then((res) => {
                if (res.status !== 200) {
                    console.log("Dans le then error if", res.error)
                    return (res.error)
                } else {
                    //console.log("Message envoyé", res)
                    setOk(false)   // pour tester plusieurs envois de mail
                    //setOk(true)
                }
            })
            .catch((err) => {
                console.log("Dans le catch : ", err.error)
            })
    }


    useEffect(() => {
        // inverser Recherche / Propose
        if (salonCovoit.choix === "Propose") { setType("Recherche") }
        if (salonCovoit.choix === "Recherche") { setType("Propose") }
    }, [salonCovoit.choix])
    console.log(salonCovoit.choix, "donc", type)

    // on enlève 1 jour à la date du salon pour ne proposer que cette date dans le datepicker
    const date_deb = moment(salonCovoit.date_aller).subtract(1, "days").format('yyyy-MM-DD');
    const date_fin = moment(salonCovoit.date_aller).add(2, "days").format('yyyy-MM-DD');

    return (
        <section>
            <Navigation />
            {ok === true ? (
                <div className=" bouton valide"><h2>Votre message a été envoyé !</h2></div>
            ) : (

                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmitForm(repCovoit);
                    }}
                >
                    <h2>Répondre à l'annonce pour</h2>
                    <h2>{params.salon}</h2>
                    <p> {salonCovoit.prenom} <span className="lowercase"> {salonCovoit.choix}</span> {salonCovoit.places} places au départ de {salonCovoit.depart} </p>

                    <div className="flex-50">
                        <div className="cust-input-group">
                            <div className="cust-input-group-prepend">
                                <span className="cust-input-group-text" id="type">Je</span>
                            </div>
                            <input
                                id="choix"
                                name="choix"
                                readOnly
                                className="cust-form-control"
                                defaultValue={type}
                                ref={choix}
                            />
                        </div>

                        <div className="cust-input-group">
                            <div className="cust-input-group-prepend">
                                <span className="cust-input-group-text" id="places">Place(s)</span>
                            </div>
                            <select
                                id="places"
                                required
                                name="places"
                                className="cust-form-control"
                                ref={places}
                            >
                                <option value="">Nombre de places</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                            </select>


                        </div>
                    </div>

                    <div className="cust-input-group">
                        <div className="cust-input-group-prepend">
                            <span className="cust-input-group-text" id="depart">Départ de</span>
                        </div>
                        <input
                            type="text"
                            required
                            className="cust-form-control"
                            placeholder="Je pars de..."
                            aria-label="Username"
                            aria-describedby="depart"
                            name="depart"
                            ref={depart}
                        />
                    </div>

                    <h3>Je souhaite partir le</h3>

                    <div className="cust-input-group flex-50">
                        <div className="cust-input-group">
                            <input
                                type="date"
                                required
                                className="cust-form-control"
                                id="date_aller"
                                name="date_aller"
                                getdate={date_deb}
                                min={date_deb}
                                max={date_fin}
                                ref={date_aller}
                            />
                        </div>

                        <div className="cust-input-group">
                            <input
                                type="time"
                                className="cust-form-control"
                                id="heure"
                                name="heure"
                                defaultValue={salonCovoit.heure}
                                ref={heure}
                            />
                        </div>
                    </div>

                    <div className="cust-input-group">
                        <div className="cust-input-group-prepend">
                            <span className="cust-input-group-text" id="nom">Nom</span>
                        </div>
                        <input
                            type="text"
                            className="cust-form-control"
                            name="nom"
                            placeholder="Votre nom"
                            defaultValue={user.infos.nom}
                        />
                    </div>


                    <div className="cust-input-group">
                        <div className="cust-input-group-prepend">
                            <span className="cust-input-group-text" id="prenom">Prenom</span>
                        </div>
                        <input
                            type="text"
                            className="cust-form-control"
                            name="prenom"
                            placeholder="Votre prénom"
                            defaultValue={user.infos.prenom}

                        />
                    </div>

                    <p><FontAwesomeIcon icon={faCircleInfo} /> Seul votre prénom apparaîtra</p>


                    <div className="cust-input-group">
                        <div className="cust-input-group-prepend">
                            <span className="cust-input-group-text" id="telephone">&nbsp;<FontAwesomeIcon icon={faPhone} /></span>
                        </div>
                        <input
                            type="text" 
                            inputmode="numeric" 
                            className="cust-form-control"
                            aria-label="Username"
                            aria-describedby="telephone"
                            name="telephone"
                            placeholder="06 XX XX XX XX"
                            ref={telephone}
                        />
                    </div>


                    <div className="cust-input-group">
                        <div className="cust-input-group-prepend">
                            <span className="cust-input-group-text" id="email">&nbsp;@</span>
                        </div>
                        <input
                            type="email"
                            diseable="true"
                            className="cust-form-control"
                            aria-label="Username"
                            aria-describedby="email"
                            name="email"
                            placeholder="vous@e-mail.com"
                            disabled
                            defaultValue={user.infos.mail}
                            ref={email}
                        />
                    </div>


                    <input
                        type="hidden"
                        defaultValue={salonCovoit.email}
                    />


                    <div className="flex-50">
                        <div className="cust-input-group">
                            <div className="cust-input-group-prepend">
                                <span className="cust-input-group-text" id="genre"> &nbsp;<FontAwesomeIcon icon={faVenusMars} /></span>
                            </div>
                            <select
                                id="genre"
                                name="genre"
                                className="cust-form-control"
                                ref={genre}
                            >
                                <option value="na">---</option>
                                <option value="masc">Homme</option>
                                <option value="fem">Femme</option>
                                <option value="other">Autre</option>
                            </select>
                        </div>

                        <div className="cust-input-group">
                            <div className="cust-input-group-prepend">
                                <span className="cust-input-group-text" id="age">Âge</span>
                            </div>
                            <select
                                id="age"
                                name="age"
                                className="cust-form-control"
                                ref={age}
                            >
                                <option value="">---</option>
                                <option value="-20">- de 20 ans</option>
                                <option value="20-30">20-30 ans</option>
                                <option value="30-40">30-40 ans</option>
                                <option value="40-50">40-50 ans</option>
                                <option value="50-60">50-60 ans</option>
                                <option value="+60">60 ans et +</option>
                            </select>
                        </div>
                    </div>

                    <span> {salonCovoit.prenom} propose </span>

                    {salonCovoit.contrepartie === "Autre" ? (
                        <b>"{salonCovoit.contrepartie_texte}"</b>
                    ) :
                        (
                            <b>"{salonCovoit.contrepartie}" </b>
                        )

                    }



                    <span> comme contrepartie </span>


                    <div className="cust-input-group">
                        <div className="cust-input-group-prepend">
                            <span className="cust-input-group-text" id="contrepartie_texte">Réponse contrepartie</span>
                        </div>
                        <input
                            type="text"
                            required
                            diseable="true"
                            className="cust-form-control"
                            name="contrepartie_texte"
                            ref={contrepartie_texte}
                        />
                    </div>


                    <div className="cust-input-group">
                        <textarea
                            id="message"

                            name="message"
                            placeholder="Complétez votre annonce par un petit message ..."
                            ref={message}
                        ></textarea>
                    </div>
                    <p style={{marginBottom: 100}}>  <FontAwesomeIcon icon={faCircleInfo} /> <a href="https://www.vinsnaturels.fr" target="_blank" rel="noreferrer" >Vinsnaturels.fr   </a> met juste en relation les utilisateurs, il ne peut être tenu responsable </p>

                    <button
                        className="envoyer valide f_bottom"
                        type="submit"
                    >
                        Envoyer
                    </button>

                </form>
            )}

        </section>
    )
}

export default Reponse_covoit