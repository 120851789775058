import { useState, useEffect } from "react"
import logo from './img/logo_covoit.jpg'
import slogan from './img/covoiturez.jpg'
import banner from './img/banner.jpg'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSlash, faUser } from '@fortawesome/free-solid-svg-icons'

import { useSelector } from "react-redux";
import { selectUser } from "../slices/userSlice";

const Header = (props) => {

    const user = useSelector(selectUser);

    return (
        <div>
            <div className="header">
                <Link to={ `/?id_salon=${window.localStorage.getItem('url_id_salon')}&salon=${window.localStorage.getItem('url_salon')}&date=${window.localStorage.getItem('url_date')}` }>
                    <img src={banner} alt="covoit" className='logo' />
                </Link>

            </div>
        </div>
    )
}

export default Header