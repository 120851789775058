import React, { useEffect, useState } from "react";
import { Link, } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../slices/userSlice";
import { getAllCovoitById } from '../../api/covoit'
import Navigation from './../Navigation'
import ListeReponses from './../Liste_reponses'
import PopUp from './../includes/Popup'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTrash } from '@fortawesome/free-solid-svg-icons'

const Profil = (props) => {
    const user = useSelector(selectUser)
    const [salonCovoit, setSalonCovoit] = useState([])
    const [isPopUp, setPopUp] = useState(false)
    const [idDelete, setIdDelete] = useState("")
    const [message, setMessage] = useState("")
    const [sup, setSup] = useState(false)

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        // récupère les paramétres de l'url
        setMessage(queryParams.get("edit") )
        
        console.log("getAllCovoitById", user.infos.id)
        getAllCovoitById(user.infos.id)
            .then((result) => {
                console.log("getAllCovoitById", result.covoitsById)
                setSalonCovoit(result.covoitsById)
                //console.log("salonCovoit", salonCovoit)

            })
            .catch(err => console.log(err))
    }, [user.infos.id, sup])

    // Pour pouvoir effacer d'autres covoits
    setTimeout(() => {
        setSup(false)
    }, 3000);

    const onClicDelete = (id) => {
        setIdDelete(id)
        setPopUp(true)
        //console.log("idDelete", id)
    }

    return (
        <div className="containeur">
            <Navigation />
            <h1> {salonCovoit.length} covoiturage(s)</h1>
            {message &&
                <div className="bouton valide">
                    <h2>{message}</h2>
                </div>
            }
            {sup &&
                <div className="bouton valide">
                    <h2>{sup}</h2>
                </div>
            }
            <PopUp
                isPopUp={isPopUp}
                id={idDelete}
                msg={`Vous êtes sur le point de supprimer votre covoiturage`}
                onClickMessage={(e) => {
                    setSup("Covoiturage supprimé !!")
                    window.scrollTo(0, 0)
                    

                }}
                onClickClose={(e) => {
                    //console.log("zzzz")
                    setPopUp(false)
                }}
            />

            <section>
                {salonCovoit.map((liste) => {
                    return (
                        <div className={`liste_covoit ${liste.choix}`} key={liste.id}>
                            <div className="row">
                                <h1><b>{liste.arrivee}</b></h1>
                                <Link className="bouton" to={`/Detail/${liste.id}`}> <ListeReponses liste={liste} /> réponse(s)  </Link>
                            </div>

                            <div className="detail">
                                <p>{liste.choix} {liste.places} place(s) </p>
                                <p>De : <b>{liste.depart}</b>, départ le <b>{liste.date_aller}</b>  à  <b>{liste.heure}</b> </p>

                                <p>Contrepartie : <b>{liste.contrepartie}</b></p>

                            </div>
                            <div className="repondre row" >
                                <Link className="bouton w200" to={`/Edit_covoit/${liste.id}`}> 
                                    Modifier 
                                </Link>

                                <Link className="w200" to={``}>
                                  <button
                                    className="bouton supprimer"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        onClicDelete(liste.id)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                                </Link>

                            </div>
                        </div>
                    )
                })}
            </section>
        </div>
    )

}

export default Profil